<template>
  <div class="market">
    <div class="title">{{ $t('AiData.zongjiazhi') }}</div>
    <div class="total q-mt-sm">
      <div class="total-jine">
        <count-up :end-val="pageData.zong" :decimalPlaces="2"></count-up>
      </div>
    </div>

    <div class="total" style="margin-top: 10px;">
      <div class="total-top">
        <div>
          <div>{{ $t('AiData.zongyue') }}</div>
          <div class="total-top-jine main-color">
            <count-up :end-val="pageData.yueSum" :decimalPlaces="2"></count-up>
          </div>
        </div>
        <img src="@/assets/img/base/ai-market.svg" class="total-top-icon" />
      </div>
      <div class="total-row">
        <div class="total-row-item">
          <div class="label">{{ $t('AiData.baozhengjin') }}</div>
          <div class="main-color"><count-up :end-val="pageData.yue1" :decimalPlaces="2"></count-up></div>
        </div>
        <div class="total-row-item">
          <div class="label">{{ $t('AiData.qianbaoyue') }}</div>
          <div class="main-color"><count-up :end-val="pageData.yue2" :decimalPlaces="2"></count-up></div>
        </div>
        <div class="total-row-item">
          <div class="label">{{ $t('AiData.yunxingyingkui') }}</div>
          <div class="main-color"><count-up :end-val="pageData.yue3" :decimalPlaces="2"></count-up></div>
        </div>
        <div class="total-row-item">
          <div class="label">{{ $t('AiData.zongyingkui') }}</div>
          <div class="main-color"><count-up :end-val="pageData.yue4" :decimalPlaces="2"></count-up></div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="list-name">
        <div>{{ $t('AiData.name') }}</div>
        <div>{{ $t('AiData.price') }}</div>
      </div>
      <div class="list-val" v-for="item in list" :key="item.id">
        <div class="row items-center">
          <img :src="require(`@/assets/img/coin/${item.symbol}.png`)" class="coin-icon" />
          <div>{{ item.symbol }} / USDT</div>
        </div>
        <div>{{ item.priceOut.toFixed(6) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CountUp from 'vue-countup-v3'

export default {
  name: 'AiDataMarket',
  props: {
    pageData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    CountUp
  },
  setup() {
    return {
      price: ref(0),
      list: ref([])
    }
  },
  mounted() {
    this.getEthContract()
  },
  methods: {
    getEthContract() {
      let _this = this
      _this.$request.post(
        "api/EthContract/List",
        {},
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let data = res.data.data
          let dogeIndex = data.findIndex(item => item.symbol === 'DOGE')
          let doge = data.splice(dogeIndex, 1)
          data.splice(3, 0, ...doge)
          _this.list = data
          // console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
.market {
  margin-top: 15px;
}

.total {
  padding: 10px;
  border-radius: 12px;
  background-color: #fff;
}

.total-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-top-icon {
  width: 60px;
}

.total-row {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.total-row-item {
  padding: 6px 0;
  width: 50%;
  font-size: 12px;
}

.label {
  color: gray;
}

.list {
  margin-top: 10px;
  padding: 10px;
  border-radius: 12px;
  background-color: #fff;
}

.list-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-name > div {
  font-size: 13px;
  color: gray;
}

.coin-icon {
  margin-right: 8px;
  width: 30px;
}

.list-val {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.total-jine {
  color: #2C8B9F;
  font-weight: bold;
  font-size: 33px;
  text-align: center;
}
</style>